var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":92},"end":{"line":10,"column":113}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button compose_upload_file zulip-icon zulip-icon-attachment\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":121},"end":{"line":11,"column":142}}}))
    + "\" tabindex=0></a>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"compose_control_button_container preview_mode_disabled\" data-tooltip-template-id=\"add-poll-tooltip\" data-tippy-maxWidth=\"none\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-poll add-poll\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add poll",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":104},"end":{"line":29,"column":121}}}))
    + "\" tabindex=0></a>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "hide";
},"7":function(container,depth0,helpers,partials,data) {
    return " tabindex=0 ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose-scrollable-buttons compose-control-buttons-container order-1\" tabindex=\"-1\">\n    <input type=\"file\" class=\"file_input notvisible\" multiple />\n    <div class=\"compose_control_button_container\" data-tooltip-template-id=\"preview-tooltip\" data-tippy-maxWidth=\"none\">\n        <a role=\"button\" class=\"markdown_preview compose_control_button zulip-icon zulip-icon-preview\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":115},"end":{"line":4,"column":131}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"compose_control_button_container\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Write",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":70},"end":{"line":6,"column":84}}}))
    + "\">\n        <a role=\"button\" class=\"undo_markdown_preview compose_control_button zulip-icon zulip-icon-compose-edit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Write",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":125},"end":{"line":7,"column":139}}}))
    + "\" tabindex=0 style=\"display:none;\"></a>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"file_upload_enabled"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add video call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":92},"end":{"line":14,"column":115}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-video-call video_link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add video call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":112},"end":{"line":15,"column":135}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add voice call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":92},"end":{"line":17,"column":115}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-voice-call audio_link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add voice call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":112},"end":{"line":18,"column":135}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"divider\"></div>\n    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":92},"end":{"line":21,"column":110}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-smile-bigger emoji_map\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":113},"end":{"line":22,"column":131}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"compose_control_button_container preview_mode_disabled\" data-tooltip-template-id=\"add-global-time-tooltip\" data-tippy-maxWidth=\"none\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-time time_pick\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add global time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":105},"end":{"line":25,"column":129}}}))
    + "\" tabindex=0></a>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"message_id"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"compose_control_button_container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"giphy_enabled"),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":49},"end":{"line":32,"column":90}}})) != null ? stack1 : "")
    + " preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":134},"end":{"line":32,"column":150}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button compose_gif_icon zulip-icon zulip-icon-gif\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":111},"end":{"line":33,"column":127}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"divider\"></div>\n    <div class=\"compose-control-buttons-container preview_mode_disabled\">\n        <a role=\"button\" data-format-type=\"link\" class=\"compose_control_button zulip-icon zulip-icon-link formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":137},"end":{"line":37,"column":150}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":152},"end":{"line":37,"column":202}}})) != null ? stack1 : "")
    + " data-tooltip-template-id=\"link-tooltip\" data-tippy-maxWidth=\"none\"></a>\n        <a role=\"button\" data-format-type=\"bold\" class=\"compose_control_button zulip-icon zulip-icon-bold formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bold",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":137},"end":{"line":38,"column":150}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":152},"end":{"line":38,"column":202}}})) != null ? stack1 : "")
    + " data-tooltip-template-id=\"bold-tooltip\" data-tippy-maxWidth=\"none\"></a>\n        <a role=\"button\" data-format-type=\"italic\" class=\"compose_control_button zulip-icon zulip-icon-italic formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Italic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":141},"end":{"line":39,"column":156}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":158},"end":{"line":39,"column":208}}})) != null ? stack1 : "")
    + " data-tooltip-template-id=\"italic-tooltip\" data-tippy-maxWidth=\"none\"></a>\n        <a role=\"button\" data-format-type=\"strikethrough\" class=\"compose_control_button zulip-icon zulip-icon-strikethrough formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Strikethrough",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":155},"end":{"line":40,"column":177}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":179},"end":{"line":40,"column":229}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Strikethrough",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":250},"end":{"line":40,"column":272}}}))
    + "\"></a>\n    </div>\n    <div class=\"divider\"></div>\n    <div class=\"compose-control-buttons-container preview_mode_disabled\">\n        <a role=\"button\" data-format-type=\"numbered\" class=\"compose_control_button zulip-icon zulip-icon-ordered-list formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Numbered list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":149},"end":{"line":44,"column":171}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":173},"end":{"line":44,"column":223}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Numbered list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":244},"end":{"line":44,"column":266}}}))
    + "\"></a>\n        <a role=\"button\" data-format-type=\"bulleted\" class=\"compose_control_button zulip-icon zulip-icon-unordered-list formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bulleted list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":151},"end":{"line":45,"column":173}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":175},"end":{"line":45,"column":225}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bulleted list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":246},"end":{"line":45,"column":268}}}))
    + "\"></a>\n        <div class=\"divider\"></div>\n        <a role=\"button\" data-format-type=\"quote\" class=\"compose_control_button zulip-icon zulip-icon-quote formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":139},"end":{"line":47,"column":153}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":155},"end":{"line":47,"column":205}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":226},"end":{"line":47,"column":240}}}))
    + "\"></a>\n        <a role=\"button\" data-format-type=\"spoiler\" class=\"compose_control_button zulip-icon zulip-icon-spoiler formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Spoiler",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":143},"end":{"line":48,"column":159}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":161},"end":{"line":48,"column":211}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Spoiler",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":232},"end":{"line":48,"column":248}}}))
    + "\"></a>\n        <a role=\"button\" data-format-type=\"code\" class=\"compose_control_button zulip-icon zulip-icon-code formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":137},"end":{"line":49,"column":150}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":152},"end":{"line":49,"column":202}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":223},"end":{"line":49,"column":236}}}))
    + "\"></a>\n        <a role=\"button\" data-format-type=\"latex\" class=\"compose_control_button zulip-icon zulip-icon-math formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Math (LaTeX)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":138},"end":{"line":50,"column":159}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":161},"end":{"line":50,"column":211}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Math (LaTeX)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":232},"end":{"line":50,"column":253}}}))
    + "\"></a>\n    </div>\n    <div class=\"divider\"></div>\n    <a role=\"button\" class=\"compose_control_button compose_help_button zulip-icon zulip-icon-question\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":134},"end":{"line":53,"column":161}}}))
    + "\" data-overlay-trigger=\"message-formatting\"></a>\n</div>\n";
},"useData":true});